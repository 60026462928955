import type { FetchMoreOptions } from '@apollo/client'
import type { UseLazyQueryReturn, UseQueryReturn } from '@vue/apollo-composable'
import type { UnwrapRef } from 'vue'

type InferredResult<T> = T extends UseQueryReturn<infer U, any> ? U : any
type InferredVariables<T> = T extends UseQueryReturn<any, infer C> ? C : any

type InferredUseQueryReturn<T> =
  | UseLazyQueryReturn<InferredResult<T>, InferredVariables<T>>
  | UseQueryReturn<InferredResult<T>, InferredVariables<T>>

export const useFetchMoreWrapper = <T extends InferredUseQueryReturn<T>>(
  query: () => T,
  updateQuery: FetchMoreOptions['updateQuery'],
  getCount: (
    result: UnwrapRef<InferredUseQueryReturn<T>['result']>,
  ) => number | undefined,
  getTotal: (
    result: UnwrapRef<InferredUseQueryReturn<T>['result']>,
  ) => number | undefined,
  errorPrefix: string,
) => {
  const { addToastError } = useToaster()

  const payload = query()

  const count = computed(() => getCount(payload.result.value) ?? 0)

  const total = computed(() => getTotal(payload.result.value) ?? 0)

  const handleError = (error: unknown) => {
    addToastError({ error, prefix: errorPrefix })
  }

  const invokeFetchMore = async () => {
    if (total.value === count.value) return

    try {
      await payload.fetchMore({
        updateQuery,
        // (TODO) later maybe not generic enough
        variables: { offset: count.value } as any,
      })
    } catch (error) {
      handleError(error)
    }
  }

  const invokeRefetch = async () => {
    try {
      await payload.fetchMore({
        updateQuery,
        // (TODO) later maybe not generic enough
        variables: { limit: count.value, offset: 0 } as any,
      })
    } catch (error) {
      handleError(error)
    }
  }

  payload.onError(handleError)

  return {
    ...payload,
    count,
    fetchMore: invokeFetchMore,
    refetch: invokeRefetch,
    total,
  }
}
